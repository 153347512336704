<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('agentlist_xq')">基本信息</span>
      <span @click="toTypeUrl('add_agentlist_js')">结算信息</span>
      <span id="fzcolor">产品信息</span>
    </div>
    <div class="header" v-else>
      <span @click="toUrl('add_agentlist_xx')">基本信息</span>
      <span @click="toUrl('add_agentlist_js')">结算信息</span>
      <span id="fzcolor">产品信息</span>
    </div>

    <div v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX'">
      <div class="desc">
        <h5 class="jsxx">支付产品</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>结算费率(%)</p>
            </td>
            <td>
              <p>分润比例(%)</p>
            </td>
            <td>
              <p>状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in payProducts" :key="i">
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ v.settleRate }}</p>
            </td>
            <td>
              <p>{{ v.profitRate }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setPayStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
              <!-- <p v-if="pageType == 'edit'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setPayStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
              <p v-else>
                <el-switch v-model="v.enabledStatus" active-color="#13ce66">
                </el-switch>
              </p> -->
            </td>
            <td>
              <p>
                <i class="lianjie" @click="editPayTab(v, i)" v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT'">编辑</i
                ><i class="lianjie" @click="delPayTab(i, v)" v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/DELETE'">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6 class="xzjsxx" @click="addPayShow"  v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/ADD'"><span>+</span> 新增支付产品</h6>
      <div class="desc">
        <h5 class="jsxx">代付产品</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>费率(%)</p>
            </td>
            <td>
              <p>对公代付(元/笔)</p>
            </td>
            <td>
              <p>对私代付(元/笔)</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in agnpayProducts" :key="i">
            <td>
              <p>
                {{
                  v.payType == "BANK_PAY_DAILY_ORDER"
                    ? "朝夕付"
                    : v.payType == "BANK_PAY_MAT_ENDOWMENT_ORDER"
                    ? "任意付"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>{{ v.payRate }}</p>
            </td>
            <td>
              <p>{{ v.payCostPublic | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.payCostPrivate | formatMoney }}</p>
            </td>
            <td>
              <p>
                <i class="lianjie" @click="editUserPayTab(v, i)" v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT'">编辑</i
                ><i class="lianjie" @click="delUserPayTab(i, v)" v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/DELETE'">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6 class="xzjsxx" @click="addUserPayShow"  v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/ADD'"><span>+</span> 新增代付产品</h6>
      <div class="desc">
        <h5 class="jsxx">分账产品</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>产品类型</p>
            </td>
            <td>
              <p>结算费率(%)</p>
            </td>
            <td>
              <p>结算成本(元/笔)</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in allocProducts" :key="i">
            <td style="padding-left: 10px">
              <p>
                {{
                  v.settleType == "AUTO"
                    ? "自动结算"
                    : v.settleType == "MANUAL"
                    ? "手工结算"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>{{ v.settleRate }}</p>
            </td>
            <td>
              <p>{{ v.settleCost | formatMoney }}</p>
            </td>
            <td>
              <p>
                <i class="lianjie" @click="editSplitTab(v, i)" v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT'">编辑</i
                ><i class="lianjie" @click="delSplitTab(i, v)" v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/DELETE'">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6 class="xzjsxx" @click="addSplitShow" v-auth="'ACCOUNT:AGENT:AGENTS:BASE/CPXX/ADD'"><span>+</span> 新增分账产品</h6>
      <!-- <div class="btn" v-if="pageType == 'edit'">
        <el-button @click="toTypeUrl('add_agentlist_js')" class="back">上一步</el-button>
        <el-button @click="toUrl('agentlist_dlslb')" class="next" type="primary">保存</el-button>
      </div> -->
      <div class="btn" v-if="pageType != 'edit'">
        <el-button @click="toUrl('add_agentlist_js')" class="back"
          >上一步</el-button
        >
        <el-button @click="submit" class="next" type="primary">保存</el-button>
      </div>
    </div>

    <!-- 新增支付产品弹框 -->
    <el-dialog
      :title="editPayIndex !== null ? '修改支付产品' : '新增支付产品'"
      :visible.sync="userPayShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="addPayFrom.productId"
            placeholder="选择产品类型"
            class="width100"
            tabindex="1"
          >
            <el-option
              v-for="item in payDropLists"
              :key="item.productId"
              :label="item.productName"
              :value="item.productId"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>结算费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addPayFrom.settleRate"
            min="0"
            max="100"
            placeholder="输入结算费率"
            class="width100"
            tabindex="3"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>分润比例(0-100%)</div>
          <el-input
            type="number"
            v-model="addPayFrom.profitRate"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            min="0"
            max="100"
            placeholder="输入分润比例"
            class="width100"
            tabindex="2"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="userPayShow = false">取 消</el-button>
        <el-button class="addBt" :disabled="buttonFlag" @click="addPay"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增代付产品弹框 -->
    <el-dialog
      :title="editUserPayIndex !== null ? '修改代付产品' : '新增代付产品'"
      :visible.sync="hasPayShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="addUserPayFrom.payType"
            placeholder="选择产品类型"
            class="width100"
            tabindex="1"
          >
            <el-option
              v-for="item in userPayDropLists"
              :key="item.value"
              :label="item.title"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addUserPayFrom.payRate"
            placeholder="输入费率"
            class="width100"
            tabindex="3"
            min="0"
            max="100"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>对公代付(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addUserPayFrom.payCostPublic"
            min="0"
            placeholder="输入金额"
            class="width100"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>对私代付(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addUserPayFrom.payCostPrivate"
            min="0"
            placeholder="输入金额"
            class="width100"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="hasPayShow = false">取 消</el-button>
        <el-button class="addBt" :disabled="buttonFlag" @click="addUserPay"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增分账产品弹框 -->
    <el-dialog
      :title="editSplitIndex !== null ? '修改分账产品' : '新增分账产品'"
      :visible.sync="accountShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="addSplitFrom.settleType"
            placeholder="选择产品类型"
            class="width100"
            tabindex="1"
          >
            <el-option
              v-for="item in splitDropLists"
              :key="item.value"
              :label="item.title"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>结算费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addSplitFrom.settleRate"
            min="0"
            max="100"
            placeholder="输入结算费率"
            class="width100"
            tabindex="3"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>结算成本(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addSplitFrom.settleCost"
            min="0"
            placeholder="输入金额"
            class="width100"
            tabindex="2"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="accountShow = false">取 消</el-button>
        <el-button class="addBt" :disabled="buttonFlag" @click="addSplit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { payproductsDrop } from "@/api/common.js";
import {
  addAgents,
  getAgentsDetail,
  setPayproducts,
  setPayproductsStatus,
  delPayproducts,
  setAgnpayproducts,
  delAgnpayproducts,
  setAllocproducts,
  delAllocproducts,
} from "@/api/user/agentsManager.js";
import { mapState, mapMutations } from "vuex";
import { isTwoDecimal } from "@/utils/common.js";

export default {
  data() {
    return {
      value: "100",
      userPayShow: false, //新增支付展示
      hasPayShow: false, //新增代付展示
      accountShow: false, //新增分账展示
      payProducts: [], //支付产品列表
      agnpayProducts: [], //代付产品列表
      allocProducts: [], //分账产品列表
      payDropLists: [], //支付下拉选择
      addPayFrom: {
        //新增支付表单
        productId: "",
        settleRate: "",
        profitRate: "",
        enabledStatus: false,
      },
      oldProductId: "", //被编辑的支付产品id
      editPayIndex: null, //修改支付
      userPayDropLists: [
        {
          //代付下拉选择
          title: "朝夕付",
          value: "BANK_PAY_DAILY_ORDER",
          disabled: false,
        },
        {
          title: "任意付",
          value: "BANK_PAY_MAT_ENDOWMENT_ORDER",
          disabled: false,
        },
      ],
      oldPayType: "", //被编辑的代付产品type
      addUserPayFrom: {
        //新增支付表单
        payType: "",
        payRate: "",
        payCostPublic: "",
        payCostPrivate: "",
      },
      editUserPayIndex: null, //修改支付
      splitDropLists: [
        {
          //分账下拉选择
          title: "自动结算",
          value: "AUTO",
          disabled: false,
        },
        {
          title: "手工结算",
          value: "MANUAL",
          disabled: false,
        },
      ],
      addSplitFrom: {
        //新增分账表单
        settleType: "",
        settleRate: "",
        settleCost: "",
      },
      oldSettleType: "", //被编辑的分账产品类型
      editSplitIndex: null, //修改分账
      pageType: "", //页面状态
      buttonFlag: false, //防止重复点击
      OFFON_EDIT: 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT',
    };
  },
  computed: {
    ...mapState({
      agentsDetail: "user_agentsDetail",
    }),
  },
  created() {
    this.getPayDrop();
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    } else {
      if (this.agentsDetail) {
        if (this.agentsDetail.agentConfig) {
          this.payProducts = this.agentsDetail.agentConfig.payProducts;
          this.agnpayProducts = this.agentsDetail.agentConfig.agnpayProducts;
          this.allocProducts = this.agentsDetail.agentConfig.allocProducts;
        }
      }
    }
    this.listJurisdiction();
  },
  methods: {
    ...mapMutations({
      setAgents: "user_setAgents",
    }),
    //获取详情
    getDetail() {
      getAgentsDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.payProducts = res.resultData.agentConfig.payProducts;
          this.agnpayProducts = res.resultData.agentConfig.agnpayProducts;
          this.allocProducts = res.resultData.agentConfig.allocProducts;
          this.agnpayProducts.forEach((v) => {
            v.payCostPublic /= 100;
            v.payCostPrivate /= 100;
          });
          this.allocProducts.forEach((v) => {
            v.settleCost /= 100;
          });
        }
      });
    },
    // 获取支付下拉选择
    getPayDrop() {
      payproductsDrop().then((res) => {
        this.payDropLists = res.resultData;
        this.payDropLists.forEach((v) => {
          v.disabled = false;
        });
      });
    },
    // 新增支付产品展示
    addPayShow() {
      this.addPayFrom = {
        productId: "",
        settleRate: "",
        profitRate: "",
        enabledStatus: false,
      };
      this.buttonFlag = false;
      this.editPayIndex = null;
      this.payDropLists.forEach((v) => {
        v.disabled = false;
      });
      this.payProducts.forEach((v) => {
        this.payDropLists.forEach((val) => {
          if (v.productId == val.productId) {
            val.disabled = true;
          }
        });
      });
      this.userPayShow = true;
    },

    // 新增支付产品
    addPay() {
      let data = JSON.parse(JSON.stringify(this.addPayFrom));
      if (!data.productId) {
        this.$message.error("产品类型必选");
        return;
      }
      if (!data.profitRate && data.profitRate !== 0) {
        this.$message.error("分润比例必填");
        return;
      }
      if (data.profitRate < 0 || data.profitRate > 100) {
        this.$message.error("分润比例范围（0-100）");
        return;
      } else {
        if (!isTwoDecimal(data.profitRate)) {
          this.$message.error("分润比例不合法(最多保留小数点后两位)");
          return;
        }
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("结算费率必填");
        return;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("结算费率不合法(最多保留小数点后两位)");
          return;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("费率范围（0-100）");
        return;
      }
      this.buttonFlag = true;

      if (this.$route.query.pageType == "edit") {
        let productId =
          this.editPayIndex == null ? data.productId : this.oldProductId;
        setPayproducts(this.$route.query.id, productId, data).then((res) => {
          if (res.resultStatus) {
            if (this.editPayIndex == null) {
              this.$message.success("新增支付产品成功");
            } else {
              this.$message.success("编辑支付产品成功");
            }
            this.getDetail();
            this.userPayShow = false;
            setTimeout(() => {
              this.buttonFlag = false;
            }, 2000);
          }
        });
      } else {
        this.payDropLists.forEach((v) => {
          if (v.productId == data.productId) {
            data.productName = v.productName;
          }
        });
        if (this.editPayIndex !== null) {
          this.payProducts[this.editPayIndex] = data;
          this.payProducts = JSON.parse(JSON.stringify(this.payProducts));
        } else {
          this.payProducts.push(data);
        }
        this.$message.success("保存成功");
        this.userPayShow = false;
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      }
    },
    // 修改支付
    editPayTab(value, index) {
      this.addPayFrom = JSON.parse(JSON.stringify(value));
      this.oldProductId = this.addPayFrom.productId;
      this.payDropLists.forEach((e) => {
        e.disabled = false;
      });
      this.payProducts.forEach((e) => {
        this.payDropLists.forEach((h) => {
          if (e.productName == h.productName) {
            h.disabled = true;
          }
        });
      });
      this.payProducts;
      this.userPayShow = true;
      this.editPayIndex = index;
    },
    // 删除支付
    delPayTab(index, value) {
      this.$confirm("此操作将删除该支付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delPayproducts(this.$route.query.id, value.productId).then(
              (res) => {
                if (res) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.payProducts.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增代付产品展示
    addUserPayShow() {
      this.addUserPayFrom = {
        payType: "",
        payRate: "",
        payCostPublic: "",
        payCostPrivate: "",
      };
      this.editUserPayIndex = null;
      this.userPayDropLists.forEach((v) => {
        v.disabled = false;
      });
      this.agnpayProducts.forEach((v) => {
        this.userPayDropLists.forEach((val) => {
          if (v.payType == val.value) {
            val.disabled = true;
          }
        });
      });
      this.hasPayShow = true;
    },
    //新增代付产品
    addUserPay() {
      let data = JSON.parse(JSON.stringify(this.addUserPayFrom));
      if (!data.payType) {
        this.$message.error("产品类型必选");
        return;
      }
      if (!data.payRate && data.payRate !== 0) {
        this.$message.error("费率必填");
        return;
      } else {
        if (!isTwoDecimal(data.payRate)) {
          this.$message.error("费率不合法(最多保留小数点后两位)");
          return;
        }
      }
      if (data.payRate < 0 || data.payRate > 100) {
        this.$message.error("费率范围（0-100）");
        return;
      }
      if (!data.payCostPublic && data.payCostPublic !== 0) {
        this.$message.error("对公代付必填");
        return;
      }
      if (data.payCostPublic < 0) {
        this.$message.error("对公代付金额至少为0元");
        return;
      }
      if (!data.payCostPrivate && data.payCostPrivate !== 0) {
        this.$message.error("对私代付必填");
        return;
      }
      if (data.payCostPrivate < 0) {
        this.$message.error("对私代付金额至少为0元");
        return;
      }
      if(data.payCostPublic.length > 8 || data.payCostPrivate.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!isTwoDecimal(data.payCostPublic) || !isTwoDecimal(data.payCostPrivate)){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      this.buttonFlag = true;
      if (this.$route.query.pageType == "edit") {
        data.payCostPublic *= 100;
        data.payCostPrivate *= 100;
        let oldType =
          this.editUserPayIndex == null ? data.payType : this.oldPayType;
        setAgnpayproducts(this.$route.query.id, oldType, data).then((res) => {
          if (res.resultStatus) {
            if (this.editUserPayIndex == null) {
              this.$message.success("新增代付产品成功");
            } else {
              this.$message.success("编辑代付产品成功");
            }
            this.getDetail();
            this.hasPayShow = false;
            setTimeout(() => {
              this.buttonFlag = false;
            }, 2000);
          } else {
            setTimeout(() => {
              this.buttonFlag = false;
            }, 2000);
          }
        });
      } else {
        if (this.editUserPayIndex !== null) {
          this.agnpayProducts[this.editUserPayIndex] = data;
        } else {
          this.agnpayProducts.push(data);
        }
        this.$message.success("保存成功");
        this.hasPayShow = false;
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      }
    },
    // 修改代付
    editUserPayTab(value, index) {
      this.addUserPayFrom = JSON.parse(JSON.stringify(value));
      this.oldPayType = this.addUserPayFrom.payType;

      this.userPayDropLists.forEach((v) => {
        v.disabled = false;
      });
      this.agnpayProducts.forEach((e) => {
        this.userPayDropLists.forEach((h) => {
          if (e.payType == h.value) {
            h.disabled = true;
          }
        });
      });
      this.hasPayShow = true;
      this.editUserPayIndex = index;
    },
    // 删除代付
    delUserPayTab(index, value) {
      this.$confirm("此操作将删除该代付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delAgnpayproducts(this.$route.query.id, value.payType).then(
              (res) => {
                if (res) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.agnpayProducts.splice(index, 1);
            // console.log("删除后的代付产品已有数据为", this.agnpayProducts);

            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增分账产品展示
    addSplitShow() {
      this.addSplitFrom = {
        settleType: "",
        settleRate: "",
        settleCost: "",
      };
      this.editSplitIndex = null;
      this.splitDropLists.forEach((v) => {
        v.disabled = false;
      });
      this.allocProducts.forEach((v) => {
        this.splitDropLists.forEach((val) => {
          if (v.settleType == val.value) {
            val.disabled = true;
          }
        });
      });
      this.accountShow = true;
    },
    //新增分账产品
    addSplit() {
      let data = JSON.parse(JSON.stringify(this.addSplitFrom));
      if (!data.settleType) {
        this.$message.error("产品类型必选");
        return;
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("结算费率必填");
        return;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("结算费率不合法(最多保留小数点后两位)");
          return;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("结算费率范围（0-100）");
        return;
      }
      if (!data.settleCost && data.settleCost !== 0) {
        this.$message.error("结算成本必填");
        return;
      }
      // if (data.settleCost < 0.01) {
      //   this.$message.error("结算成本金额至少为0.01");
      //   return;
      // }
      if(data.settleCost.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!isTwoDecimal(data.settleCost)){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      this.buttonFlag = true;
      if (this.$route.query.pageType == "edit") {
        data.settleCost *= 100;
        let settleType =
          this.editSplitIndex == null ? data.settleType : this.oldSettleType;
        setAllocproducts(this.$route.query.id, settleType, data).then((res) => {
          if (res.resultStatus) {
            if (this.editSplitIndex == null) {
              this.$message.success("新增分账产品成功");
            } else {
              this.$message.success("编辑分账产品成功");
            }
            this.getDetail();
            this.accountShow = false;
            setTimeout(() => {
              this.buttonFlag = false;
            }, 2000);
          } else {
            setTimeout(() => {
              this.buttonFlag = false;
            }, 2000);
          }
        });
      } else {
        if (this.editSplitIndex !== null) {
          this.allocProducts[this.editSplitIndex] = data;
        } else {
          this.allocProducts.push(data);
        }
        this.$message.success("保存成功");
        this.accountShow = false;
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      }
    },
    // 修改分账
    editSplitTab(value, index) {
      this.addSplitFrom = JSON.parse(JSON.stringify(value));
      // console.log("编辑分账产品",this.addSplitFrom)
      this.oldSettleType = this.addSplitFrom.settleType;

      this.splitDropLists.forEach((v) => {
        v.disabled = false;
      });
      // console.log("分账产品类型可选项为", this.splitDropLists);
      // console.log("分账产品已有数据为", this.allocProducts);
      this.allocProducts.forEach((e) => {
        this.splitDropLists.forEach((h) => {
          if (e.settleType == h.value) {
            h.disabled = true;
          }
        });
      });
      this.editSplitIndex = index;
      this.accountShow = true;
    },
    // 删除分账
    delSplitTab(index, value) {
      this.$confirm("此操作将删除该分账产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delAllocproducts(this.$route.query.id, value.settleType).then(
              (res) => {
                if (res) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.allocProducts.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //提交
    submit() {
      if (
        this.payProducts.length <= 0 &&
        this.agnpayProducts.length <= 0 &&
        this.allocProducts.length <= 0
      ) {
        this.$message.error("产品至少一项");
        return;
      }
      let agentsDetail = {
        agentDetail: this.agentsDetail ? this.agentsDetail.agentDetail : null,
        settlements: this.agentsDetail ? this.agentsDetail.settlements : null,
        agentConfig: {
          payProducts: JSON.parse(JSON.stringify(this.payProducts)),
          agnpayProducts: JSON.parse(JSON.stringify(this.agnpayProducts)),
          allocProducts: JSON.parse(JSON.stringify(this.allocProducts)),
        },
      };
      agentsDetail.agentConfig.agnpayProducts.forEach((v) => {
        v.payCostPublic *= 100;
        v.payCostPrivate *= 100;
      });
      agentsDetail.agentConfig.allocProducts.forEach((v) => {
        v.settleCost *= 100;
      });
      this.setAgents(agentsDetail);
      addAgents(agentsDetail).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.setAgents(null);
          this.$router.replace({
            name: "agentlist_dlslb",
          });
        }
      });
    },
    // 页面跳转
    toUrl(name) {
      this.$router.replace({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      //    console.log('name',name)
      // return
      this.$router.replace({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
    //修改支付产品状态
    setPayStatus(value) {
      if(this.$route.query.pageType == "edit"){
        let data = {
          agentId: this.$route.query.id,
          productId: value.productId,
          enabledStatus: value.enabledStatus,
        };
        setPayproductsStatus(data).then((res) => {
          if (!res) {
            value.enabledStatus = value.enabledStatus ? false : true;
          }
        });
      }
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
          this.OFFON_EDIT = 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT') !== -1 ){
            this.OFFON_EDIT = 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT';
          }else{
            this.OFFON_EDIT = "";
          }
        }

    },
  },
  beforeDestroy() {
    let agentsDetail = {
      agentDetail: this.agentsDetail ? this.agentsDetail.agentDetail : null,
      settlements: this.agentsDetail ? this.agentsDetail.settlements : null,
      agentConfig: {
        payProducts: this.payProducts,
        agnpayProducts: this.agnpayProducts,
        allocProducts: this.allocProducts,
      },
    };
    this.setAgents(agentsDetail);
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";

.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
</style>
