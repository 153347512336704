import axios from '@/utils/request'

// 代理商列表
export function agentsLists(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/agents`,
    method: 'get',
		params
  })
}
// 新增代理商
export function addAgents(data) {
  return axios({
    url: `/manager/account/agents`,
    method: 'post',
		data
  })
}
// 代理商详情
export function getAgentsDetail(agentId) {
  return axios({
    url: `/manager/account/agents/${agentId}`,
    method: 'get'
  })
}
// 修改代理商详情
export function setAgentsDetail(agentId, data) {
  return axios({
    url: `/manager/account/agents/${agentId}`,
    method: 'put',
		data
  })
}
// 审核代理商
export function auditAgents(agentId, data) {
  return axios({
    url: `/manager/account/agents/${agentId}/check`,
    method: 'put',
		data
  })
}
// 激活/冻结代理商
export function setAgentsStatus(agentId, data) {
  return axios({
    url: `/manager/account/agents/${agentId}/activate`,
    method: 'put',
		data
  })
}
// 新增/编辑结算账户
export function setSettlements(agentId, data) {
  return axios({
    url: `/manager/account/agents/${agentId}/settlements/${data.settlementId}`,
    method: 'post',
		data
  })
}
// 删除结算账户
export function delSettlements(agentId, settlementId) {
  return axios({
    url: `/manager/account/agents/${agentId}/settlements/${settlementId}`,
    method: 'delete'
  })
}
// 新增/编辑支付产品
export function setPayproducts(agentId, productId,data) {
  return axios({
    url: `/manager/account/agents/${agentId}/payproducts/${productId}`,
    method: 'post',
		data
  })
}
// 启用/关闭支付产品
export function setPayproductsStatus(data) {
  return axios({
    url: `/manager/account/agents/${data.agentId}/payproducts/${data.productId}/status`,
    method: 'put',
		data
  })
}
// 删除支付产品
export function delPayproducts(agentId, productId) {
  return axios({
    url: `/manager/account/agents/${agentId}/payproducts/${productId}`,
    method: 'delete'
  })
}
// 新增/编辑代付产品
export function setAgnpayproducts(agentId,oldType, data) {
  return axios({
    url: `/manager/account/agents/${agentId}/agnpayproducts/${oldType}`,
    method: 'post',
		data
  })
}
// 删除代付产品
export function delAgnpayproducts(agentId, payType) {
  return axios({
    url: `/manager/account/agents/${agentId}/agnpayproducts/${payType}`,
    method: 'delete'
  })
}
// 新增/编辑分账产品
export function setAllocproducts(agentId,settleType, data) {
  return axios({
    url: `/manager/account/agents/${agentId}/allocproducts/${settleType}`,
    method: 'post',
		data
  })
}
// 删除编辑分账产品
export function delAllocproducts(agentId, settleType) {
  return axios({
    url: `/manager/account/agents/${agentId}/allocproducts/${settleType}`,
    method: 'delete'
  })
}
// 代理商管理-重发邮件
export function resend(agentId) {
  return axios({
    url: `/manager/account/agents/${agentId}/email/resend`,
    method: 'post'
  })
}
